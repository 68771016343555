@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: "Inter", sans-serif;
}
h1 {
  font-weight: 700;
}
h1,
h3 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.contentWrap {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
}
.labelWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}
.labelWrap h4 {
  pointer-events: all;
}
p {
  font-weight: 400;
}

@media (max-width: 1600px) {
  .contentWrap {
    flex-direction: column;
    width: 100%;
    height: 90vh;
    top: 5vh;
    align-items: center;
    text-align: center;
  }
  .labelWrap {
    align-items: center !important;
  }
}
